<template>
  <app-form-autocomplete
    v-model="selected"
    v-model:query="query"
    :hint="hint"
    icon="ph:tag"
    item-title="label"
    item-value="id"
    :items="flattenResults"
    :label="label ?? 'Tags'"
    :loading="status === 'pending'"
    :multiple="multiple"
    :name="name"
    :required="required"
    @update:model-value="onInput"
  >
    <template #selection="{ item, props }">
      <tag-chip v-bind="props" :tag="item" />
    </template>
    <template #item="{ item, props }">
      <v-list-item
        v-bind="props"
        color="primary"
        :disabled="exclude?.includes(item.id)"
        :prepend-icon="item.icon"
        :subtitle="item.path.replaceAll('.', '/')"
        :title="item.label"
      />
    </template>
  </app-form-autocomplete>
</template>

<script lang="ts" setup>
import { useFuse } from "@vueuse/integrations/useFuse";

import type { TagView } from "@/business-areas/tag/tag.model";

const properties = defineProps<{
  modelValue?: number | number[];
  multiple?: boolean;
  required?: boolean;
  hint?: string;
  label?: string;
  name?: string;
  exclude?: DatabaseTable<"tags">["id"][];
  children_of?: DatabaseTable<"tags">["id"];
}>();

const emit = defineEmits<{
  (event: "update:modelValue", payload?: number | number[]): void;
}>();

const client = useDatabaseClient();

const { data, status } = useLazyAsyncData(
  async () => {
    let query = client.from("tags").select();

    if (properties.children_of !== undefined) {
      const { data: parent } = await client
        .from("tags")
        .select()
        .eq("id", properties.children_of)
        .single();

      if (parent) {
        query = query.filter("path", "match", `^${parent.path}.`);
      }
    }

    const { data } = await query.order("path");

    return data;
  },
  {
    watch: [computed(() => properties.children_of)],
  },
);

const query = ref("");
const { results } = useFuse(
  query,
  computed(() => data.value ?? []),
  {
    matchAllWhenSearchEmpty: true,
    fuseOptions: {
      keys: ["label", "path", "slug"],
      threshold: 0.3,
    },
  },
);
const flattenResults = computed(() => results.value.map((i) => i.item));

const selected = ref<TagView | TagView[]>();

if (properties.modelValue !== undefined) {
  const ids = Array.isArray(properties.modelValue)
    ? properties.modelValue
    : [properties.modelValue];

  if (ids.length > 0) {
    const { data } = useAsyncData(
      `tag_autocomplete_${properties.name ?? useId()}`,
      async () => {
        const response = await useDatabaseClient()
          .from("tags")
          .select()
          .in("id", ids);

        return response.data;
      },
    );

    whenever(data, (value) => {
      selected.value = properties.multiple
        ? ids.map((id) => value.find((tag) => tag.id === id) as TagView)
        : value[0];
    });
  }
}

// For obscure reason type is not well inferred on the input event from app autocomplete
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onInput = (payload?: any) => {
  if (Array.isArray(payload)) {
    return emit(
      "update:modelValue",
      payload.map((item) => item.id),
    );
  }

  return emit("update:modelValue", payload?.id);
};
</script>
